import React, {Component, Fragment} from 'react';
import ReactCompareImage from 'react-compare-image';

import {Link} from 'react-router-dom';

import LeftImage from '../../img/services-before-photo.jpg';
import RightImage from '../../img/services-after-photo.jpg';

import LeftImage2 from '../../img/services-before-homestaging.jpg';
import RightImage2 from '../../img/services-after-homestaging.jpg';
import LeftImage3 from '../../img/services-before-homestaging2.jpg';
import RightImage3 from '../../img/services-after-homestaging2.jpg';
import LeftImage4 from '../../img/services-before-homestaging3.jpg';
import RightImage4 from '../../img/services-after-homestaging3.jpg';

import './Services.css';


class Photography extends Component{

    render(){
        return (
        <Fragment>
            <div className="services-container content">
                <div className="services-flex">
                    <div>
                        <h2>
                        Photographie
                        </h2>   
                    </div>
                    <div className="services-desc">
                        Mettez vos espaces en valeur par des clichés hautes définitions.
                    </div>
                </div> 
            </div>
            <div className="services-containeralt">
                <div className="content services-beforafter">
                    <ReactCompareImage leftImage={LeftImage} rightImage={RightImage} sliderPositionPercentage="0.4" />
                    <br />
                    <b>Avant / Après</b>
                </div>
            </div>

            <div className="services-container content">
                <div className="services-flex">
                    <div>
                        <h2>
                        Home Staging
                        </h2>   
                    </div>
                    <div className="services-desc">
                        Présentez vos biens sous leurs meilleurs angles avec un agencement préalable des espaces.
                    </div>
                </div> 
            </div>
            <div className="services-containeralt">
                <div className="content services-beforafter">
                    <ReactCompareImage leftImage={LeftImage2} rightImage={RightImage2} sliderPositionPercentage="0.5" />
                    <br />
                    <b>Avant / Après</b>
                </div>
                <div className="content services-beforafter">
                    <ReactCompareImage leftImage={LeftImage3} rightImage={RightImage3} sliderPositionPercentage="0.5" />
                    <br />
                    <b>Avant / Après</b>
                </div>
                <div className="content services-beforafter">
                    <ReactCompareImage leftImage={LeftImage4} rightImage={RightImage4} sliderPositionPercentage="0.5" />
                    <br />
                    <b>Avant / Après</b>
                </div>
            </div>

            <div className="services-cta">
            <div className="content">
                <h2>
                    Intéressé ?
                </h2>
                <h3>
                    Réalisez votre devis en ligne en 2 minutes !
                </h3><br />
                <Link to="/contact" className="service-cta-btn">» Allons-y</Link>
            </div>
            </div>
            
        </Fragment>
        )
    }
}

export default Photography;
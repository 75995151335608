import React, {Component, Fragment} from 'react';
import ReactCompareImage from 'react-compare-image';


import { NavLink } from 'react-router-dom';

import LeftImage from '../../img/Hotel/01 - Blomming av.jpg';
import RightImage from '../../img/Hotel/01 - Blomming ap.jpg';

import LeftImage2 from '../../img/Hotel/02 - Piano vue av.jpg';
import RightImage2 from '../../img/Hotel/02 - Piano vue ap.jpg';

import LeftImage3 from '../../img/Hotel/03 - Piano seree av.jpg';
import RightImage3 from '../../img/Hotel/03 - Piano seree ap.jpg';

import LeftImage4 from '../../img/Hotel/04 - chambre av.jpg';
import RightImage4 from '../../img/Hotel/04 - chambre ap.jpg';


class Hotel extends Component{

    render(){
        return (
        <Fragment>
            <div className="portfolio-menu content">
                <NavLink to="/comparaison" className="portfolio-menu-a"  activeClassName="portfolio-menu-a-selected" exact>
                    Immobilier
                </NavLink>
                <NavLink to="/comparaison/hotellerie" className="portfolio-menu-a"  activeClassName="portfolio-menu-a-selected" exact>
                    Hôtellerie 
                </NavLink>
            </div>
            <div className="services-container content">
                <div className="services-flex">
                    <div>
                        <h2>
                        Avant/Après
                        </h2>   
                    </div>
                </div> 
            </div>
            <div className="services-containeralt">
                <div className="content services-beforafter">
                    <ReactCompareImage leftImage={LeftImage4} rightImage={RightImage4} sliderPositionPercentage="0.5" />
                    <br />
                    <b>Avant / Après</b>
                </div>
                <div className="content services-beforafter">
                    <ReactCompareImage leftImage={LeftImage} rightImage={RightImage} sliderPositionPercentage="0.5" />
                    <br />
                    <b>Avant / Après</b>
                </div>
                <div className="content services-beforafter">
                    <ReactCompareImage leftImage={LeftImage2} rightImage={RightImage2} sliderPositionPercentage="0.5" />
                    <br />
                    <b>Avant / Après</b>
                </div>
                <div className="content services-beforafter">
                    <ReactCompareImage leftImage={LeftImage3} rightImage={RightImage3} sliderPositionPercentage="0.5" />
                    <br />
                    <b>Avant / Après</b>
                </div>
            </div>

            
        </Fragment>
        )
    }
}

export default Hotel;
import React, {Component, Fragment} from 'react';

import {Link} from 'react-router-dom';

import './Home.css';


class Home extends Component{
    constructor(props) {
        super(props);

    }

    render(){
        return (
        <Fragment>
            <div className="home-container">
                <Link to="/portfolio/immobilier" className="home-content">
                    <div className="home-immo">
                        <div className="home-element">
                        </div>
                    </div>
                    <div className="home-element-title">
                        Découvrir les photographies immobilières
                    </div>
                </Link>
                <Link to="/portfolio/hotellerie" className="home-content">
                    <div className="home-hotel">
                        <div className="home-element">
                        </div>
                    </div>
                    <div className="home-element-title">
                        Découvrir les photographies hôtelières
                    </div>
                </Link>

                <Link to="/portfolio/portrait" className="home-content">
                    <div className="home-portrait">
                        <div className="home-element">
                        </div>
                    </div>
                    <div className="home-element-title">
                        Découvrir les photographies portraits
                    </div>
                </Link>
                
            </div>
        </Fragment>
        )
    }
}

export default Home;
import React, {Component, Fragment} from 'react';

import {Link} from 'react-router-dom';

import './Estimate.css';

import Home from '../../img/home.png';
import Castle from '../../img/castle.png';
import Appart from '../../img/appart.png';


class EstiImmo extends Component{
    constructor(props) {
        super(props);
        this.state = {
            step: '1',
            category: '',
            surface:'',
            yard:'unknown',
            error: '',
            photoInt: '1',
            photoAer: '',
            visitVirt: '',
            blueprint: '',
            vidAer: '',
            vidInt: ''
        }
        this.setCategory = this.setCategory.bind(this);
        this.setSurface = this.setSurface.bind(this);
        this.setYard = this.setYard.bind(this);
        this.setStep = this.setStep.bind(this);
        this.togglePhotoInt = this.togglePhotoInt.bind(this);
        this.togglePhotoAer = this.togglePhotoAer.bind(this);
        this.toggleVisitVirt = this.toggleVisitVirt.bind(this);
        this.toggleBlueprint = this.toggleBlueprint.bind(this);
        this.toggleVidAer = this.toggleVidAer.bind(this);
        this.toggleVidInt = this.toggleVidInt.bind(this);
    }
    setCategory = (category) => (e) => {
        this.setState({
            category:category,
            step:2
        });
        e.preventDefault();
    }
    setSurface = (e) => {
        this.setState({ surface: e.target.value });
        if(isNaN(e.target.value)){
            this.setState({ error: 'Surface Incorrecte.' });
        }else{
            this.setState({ error: '' });
        }
    }
    setYard = (yard) => (e) => {
        this.setState({ yard: yard });
    }
    setStep= (step) => (e) => {
        this.setState({ step: step, error:'' });
    }
    togglePhotoInt = (e) => {
        if(this.state.photoInt)
            this.setState({ photoInt:''});
        else 
            this.setState({ photoInt: 1});
    }
    togglePhotoAer = (e) => {
        if(this.state.photoAer)
            this.setState({ photoAer:''});
        else 
            this.setState({ photoAer: 1});
    }
    toggleVisitVirt = (e) => {
        if(this.state.visitVirt)
            this.setState({ visitVirt:''});
        else 
            this.setState({ visitVirt: 1});
    }
    toggleBlueprint = (e) => {
        if(this.state.blueprint)
            this.setState({ blueprint:''});
        else 
            this.setState({ blueprint: 1});
    }
    toggleVidAer = (e) => {
        if(this.state.vidAer)
            this.setState({ vidAer:''});
        else 
            this.setState({ vidAer: 1});
    }
    toggleVidInt = (e) => {
        if(this.state.vidInt)
            this.setState({ vidInt:''});
        else 
            this.setState({ vidInt: 1});
    }
    render(){
        return (
        <Fragment>
            <center>
                <h3>Devis reportage immobilier</h3>
            </center>
            {this.state.step==1 &&
                <Fragment>
                    <center>
                        <h1 className="estimate-title">Type de bien</h1>
                    </center>
                    <div className="estimate-container content">
                        <div to="/devis/immo" className="estimate-content" onClick={this.setCategory('House')}>
                            <div className="estimate-element">
                            </div>
                            <div className="estimate-element-title">
                                <img src={Home} width="100" /><br />
                                Maison
                            </div>
                        </div>
                        <div to="/portfolio/immobilier" className="estimate-content" onClick={this.setCategory('Appart')}>
                            <div className="estimate-element">
                            </div>
                            <div className="estimate-element-title">
                                <img src={Appart} width="100" /><br />
                                Appartement 
                            </div>
                        </div>
                        <div to="/portfolio/immobilier" className="estimate-content" onClick={this.setCategory('Prestige')}>
                            <div className="estimate-element">
                            </div>
                            <div className="estimate-element-title">
                                <img src={Castle} width="100" /><br />
                                Bien de prestige
                            </div>
                        </div>    
                    </div>
                </Fragment>
            }
            {this.state.step==2 &&
                <Fragment>
                    <center>
                        <h1 className="estimate-title">Informations complémentaires</h1>
                    </center>
                    <div className="content estimate-form">
                    <label className="estimate-form-info-label">Surface :</label> <span><input type="text" className="surface" name="surface" onChange={this.setSurface} /> m<sup>2</sup></span>
                    <br /><br />
                    <label  className="estimate-form-info-label">Jardin :</label> <span> <div className={`yes ${this.state.yard==1 ? "choice-selected": "choice-no-selected" }`} onClick={this.setYard(1)}>Oui</div><div className={`no ${this.state.yard==0 ? "choice-selected": "choice-no-selected" }`} onClick={this.setYard(0)}>Non</div> </span>
                        <br />
                    </div><br /><br />
                    <center>
                        {!this.state.error ?
                        <div className="estimate-btn" onClick={this.setStep(3)}>Suivant</div>
                        :
                        <div className="error">{this.state.error}</div>
                        }
                    </center>
                </Fragment>
            }
            {this.state.step==3 &&
                <Fragment>
                    <center>
                        <h1 className="estimate-title">Services Souhaités</h1>
                    </center>
                    <div className="content estimate-form">
                    <div className={`estimate-service ${this.state.photoInt==1 ? "choice-selected": "choice-no-selected" }`} onClick={this.togglePhotoInt}>
                            Photographie Intérieur
                            {this.state.photoInt && <div className="estimate-service-cross"><i class="fas fa-times-circle"></i></div> }
                        </div><br />
                        <div className={`estimate-service ${this.state.photoAer==1 ? "choice-selected": "choice-no-selected" }`}  onClick={this.togglePhotoAer}>
                            Photographie Aérienne
                            {this.state.photoAer && <div className="estimate-service-cross"><i class="fas fa-times-circle"></i></div> }
                        </div><br />
                        <div className={`estimate-service ${this.state.visitVirt==1 ? "choice-selected": "choice-no-selected" }`}  onClick={this.toggleVisitVirt}>
                            Visite Virtuelle
                            {this.state.visitVirt && <div className="estimate-service-cross"><i class="fas fa-times-circle"></i></div> }
                        </div><br />
                        <div className={`estimate-service ${this.state.blueprint==1 ? "choice-selected": "choice-no-selected" }`}  onClick={this.toggleBlueprint}>
                            Plan
                            {this.state.blueprint && <div className="estimate-service-cross"><i class="fas fa-times-circle"></i></div> }
                        </div><br />
                        <div className={`estimate-service ${this.state.vidAer==1 ? "choice-selected": "choice-no-selected" }`}  onClick={this.toggleVidAer}> 
                            Vidéo Aérienne
                            {this.state.vidAer && <div className="estimate-service-cross"><i class="fas fa-times-circle"></i></div> }
                        </div><br />
                        <div className={`estimate-service ${this.state.vidInt==1 ? "choice-selected": "choice-no-selected" }`}  onClick={this.toggleVidInt}>
                            Vidéo Intérieur
                            {this.state.vidInt && <div className="estimate-service-cross"><i class="fas fa-times-circle"></i></div> }
                        </div>
                    </div><br /><br />
                    <center>
                        {!this.state.error ?
                        <div className="estimate-btn" onClick={this.setStep(4)}>Suivant</div>
                        :
                        <div className="error">{this.state.error}</div>
                        }
                    </center>
                </Fragment>
            }
            {this.state.step==4 &&
                <Fragment>
                    <center>
                        <h1 className="estimate-title">Informations personnelles :</h1>
                    </center>
                    <div className="estimate-form-info">
                        <form>
                            <label>Pays </label><br/>
                            <input type="text"  className="estimate-input" placeholder="Pays" /> <br />
                            <label>Ville </label><br/>
                            <input type="text"  className="estimate-input" placeholder="Ville" /> <br />
                            <label>Nom et prénom </label><br/>
                            <input type="text"  className="estimate-input" placeholder="Nom & Prénom" /> <br /> 
                            <label>Numéro de téléphone </label><br/>
                            <input type="tel"   className="estimate-input" placeholder="Numéro de téléphone" /> <br /> 
                            <label>Adresse email </label><br/>
                            <input type="email" className="estimate-input" placeholder="Adresse email" /> <br /> <br />

                            <center>
                                {!this.state.error ?
                                <button className="estimate-calculate-btn" onClick={this.setStep(4)}>Calculer le prix</button>
                                :
                                <div className="error">{this.state.error}</div>
                                }
                            </center>
                        </form>
                        </div>
                </Fragment>
            }
        </Fragment>
        )
    }
}

export default EstiImmo;
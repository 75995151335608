import React, {Component, Fragment} from 'react';
import ReactCompareImage from 'react-compare-image';


import { NavLink } from 'react-router-dom';

import LeftImage from '../../img/Immo/01 - salon av.jpg';
import RightImage from '../../img/Immo/01 - salon ap.jpg';

import LeftImage2 from '../../img/Immo/02 - Bxl av.jpg';
import RightImage2 from '../../img/Immo/02 - Bxl ap.jpg';

import LeftImage3 from '../../img/Immo/03 - Cuisine av.jpg';
import RightImage3 from '../../img/Immo/03 - Cuisine ap.jpg';

import LeftImage4 from '../../img/Immo/04 - Chambre av.jpg';
import RightImage4 from '../../img/Immo/04 - Chambre ap.jpg';

import LeftImage5 from '../../img/Immo/05 - Mur av.jpg';
import RightImage5 from '../../img/Immo/05 - Mur ap.jpg';

import LeftImage6 from '../../img/Immo/06 - Ensemble av.jpg';
import RightImage6 from '../../img/Immo/06 - Ensemble ap.jpg';

import LeftImage7 from '../../img/Immo/07 - Sam av.jpg';
import RightImage7 from '../../img/Immo/07 - Sam ap.jpg';


class Immo extends Component{

    render(){
        return (
        <Fragment>
            <div className="portfolio-menu content">
                <NavLink to="/comparaison" className="portfolio-menu-a"  activeClassName="portfolio-menu-a-selected" exact>
                    Immobilier
                </NavLink>
                <NavLink to="/comparaison/hotellerie" className="portfolio-menu-a"  activeClassName="portfolio-menu-a-selected" exact>
                    Hôtellerie 
                </NavLink>
            </div>
            <div className="services-container content">
                <div className="services-flex">
                    <div>
                        <h2>
                        Avant/Après
                        </h2>   
                    </div>
                </div> 
            </div>
            <div className="services-containeralt">
                <div className="content services-beforafter">
                    <ReactCompareImage leftImage={LeftImage} rightImage={RightImage} sliderPositionPercentage="0.5" />
                    <br />
                    <b>Avant / Après</b>
                </div>
                <div className="content services-beforafter">
                    <ReactCompareImage leftImage={LeftImage2} rightImage={RightImage2} sliderPositionPercentage="0.5" />
                    <br />
                    <b>Avant / Après</b>
                </div>
                <div className="content services-beforafter">
                    <ReactCompareImage leftImage={LeftImage3} rightImage={RightImage3} sliderPositionPercentage="0.5" />
                    <br />
                    <b>Avant / Après</b>
                </div>
                <div className="content services-beforafter">
                    <ReactCompareImage leftImage={LeftImage4} rightImage={RightImage4} sliderPositionPercentage="0.5" />
                    <br />
                    <b>Avant / Après</b>
                </div>
                <div className="content services-beforafter">
                    <ReactCompareImage leftImage={LeftImage5} rightImage={RightImage5} sliderPositionPercentage="0.5" />
                    <br />
                    <b>Avant / Après</b>
                </div>
                <div className="content services-beforafter">
                    <ReactCompareImage leftImage={LeftImage6} rightImage={RightImage6} sliderPositionPercentage="0.5" />
                    <br />
                    <b>Avant / Après</b>
                </div>
                <div className="content services-beforafter">
                    <ReactCompareImage leftImage={LeftImage7} rightImage={RightImage7} sliderPositionPercentage="0.5" />
                    <br />
                    <b>Avant / Après</b>
                </div>
            </div>

            
        </Fragment>
        )
    }
}

export default Immo;
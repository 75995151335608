import React, {Component} from 'react';
import { Link } from 'react-router-dom';

class Footer extends Component{

    render(){
        return (
        <footer className="footer">		
            &copy; 2019 Vrancx Real Estate Photography<br />
            <Link to ="/CGU">Conditions générales</Link>
		</footer>
        )
    }
}

export default Footer;
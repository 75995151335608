import React, {Component, Fragment} from 'react';
import { NavLink } from 'react-router-dom';
import Api, {mainURI} from '../../res/api'; 

import Viewer from './Viewer';

import './Portfolio.css';


class Portfolio extends Component{
    constructor(props) {
        super(props);
        this.state = {
            categoryToShow: '',
            showViewer: '',
            images: []
        }
        this.showCategory= this.showCategory.bind(this);
        this.openViewer= this.openViewer.bind(this);
        this.showNext= this.showNext.bind(this);
        this.showPrevious= this.showPrevious.bind(this);
        this.keyListener = this.keyListener.bind(this);
    }
    componentDidMount() {
        this.setState({categoryToShow: this.props.category})
        Api.fetchPortfolio()
        .then( response =>{
            if(response.images){
                this.setState({images: response.images});
            }
        });
        document.addEventListener("keydown", this.keyListener, false);
      }
    componentWillReceiveProps(nextProps) {
        if (nextProps.category !== this.state.categoryToShow) {
            this.setState({ categoryToShow: nextProps.category});

        }
    }
    componentWillUnmount(){
        document.removeEventListener("keydown", this.keyListener, false);
    }
    keyListener(event){
        if(event.keyCode === 27) {
            this.setState({
                showViewer: false
            });
        }
        if(event.keyCode === 37 ) {
            if(this.state.categoryToShow){
                var i= this.state.showViewer;
                while(i>=0){
                    if(this.state.images[i-2]){
                        if(this.state.images[i-2].category == this.state.categoryToShow){
                            this.setState({showViewer: i-1}); 
                            break;
                        }else{
                            i--;
                        }
                    }else{
                        break;
                    }
                }
            }else{
                if(this.state.showViewer>1){
                    this.setState({showViewer: this.state.showViewer-1}); 
                }
            }
        }
        if(event.keyCode === 39) {
            if(this.state.categoryToShow){
                var i= this.state.showViewer;
                while(i<30){
                    if(this.state.images[i]){
                        if(this.state.images[i].category == this.state.categoryToShow){
                            this.setState({showViewer: i+1}); 
                            break;
                        }else{
                            i++;
                        }
                    }else{
                        break;
                    }
                }
            }else{
                if(this.state.showViewer< this.state.images.length)
                    this.setState({showViewer: this.state.showViewer+1}); 
            }
        }
    }
    showCategory = (category) => (e) => {
        e.preventDefault();
        this.setState({
            categoryToShow: category
        });
    }
    openViewer = (selected) => (e) => {
        e.preventDefault();
        if(this.state.showViewer){
            this.setState({showViewer: ''}); 
        }else{
            this.setState({showViewer: selected+1}); 
        }
    }
    showNext = (selected) => (e) => {
        e.preventDefault();
        e.stopPropagation();
        if(this.state.categoryToShow){
            var i= this.state.showViewer;
            while(i<30){
                if(this.state.images[i]){
                    if(this.state.images[i].category == this.state.categoryToShow){
                        this.setState({showViewer: i+1}); 
                        break;
                    }else{
                        i++;
                    }
                }else{
                    break;
                }
            }
        }else{
            if(this.state.showViewer< this.state.images.length)
                this.setState({showViewer: this.state.showViewer+1}); 
        }
    }
    showPrevious = (selected) => (e) => {
        e.stopPropagation();
        e.preventDefault();
        if(this.state.categoryToShow){
            var i= this.state.showViewer;
            while(i>=0){
                if(this.state.images[i-2]){
                    if(this.state.images[i-2].category == this.state.categoryToShow){
                        this.setState({showViewer: i-1}); 
                        break;
                    }else{
                        i--;
                    }
                }else{
                    break;
                }
            }
        }else{
            if(this.state.showViewer>1){
                this.setState({showViewer: this.state.showViewer-1}); 
            }
        }
    }
    render(){
        return (
        <Fragment>
            {this.state.showViewer &&(
                <div>
                    <div className="viewer-wrapper" onClick={this.openViewer()}>
                    
                            <div className="viewer-content-element">
                            <img src={mainURI+"uploads/fullsize/"+this.state.images[this.state.showViewer-1].image} onClick={this.showNext()} />
                            
                            <div className="viewer-previous" onClick={this.showPrevious()}>
                                <i class="fas fa-chevron-left"></i>
                            </div>
                            <div className="viewer-next" onClick={this.showNext()}>
                            <i class="fas fa-chevron-right"></i>
                            </div>
                        </div>

                    </div>
                    
                </div>
            )}
            <div className="portfolio-menu content">
                <NavLink to="/portfolio" className="portfolio-menu-a"  activeClassName="portfolio-menu-a-selected" exact>
                    Toutes
                </NavLink>
                <NavLink to="/portfolio/immobilier" className="portfolio-menu-a"  activeClassName="portfolio-menu-a-selected" exact>
                    Immobilier
                </NavLink>
                <NavLink to="/portfolio/hotellerie" className="portfolio-menu-a"  activeClassName="portfolio-menu-a-selected" exact>
                    Hôtellerie 
                </NavLink>
                <NavLink to="/portfolio/portrait" className="portfolio-menu-a"  activeClassName="portfolio-menu-a-selected" exact>
                    Portrait
                </NavLink>
            </div>
            <div className="portfolio-container">
                {this.state.images.map((image, index) =>
                    <Fragment key={index}>
                    {(!this.state.categoryToShow || this.state.categoryToShow==image.category) &&
                    <div className="portfolio-content" onClick={this.openViewer(index)}  > 
                        <img src={mainURI+"uploads/thumb/"+image.image} alt="" />
                    </div>
                    }
                    </Fragment>
                )}
            </div>
        </Fragment>
        )
    }
}

export default Portfolio;
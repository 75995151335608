import React, {Component, Fragment} from 'react';


import './Portfolio.css';


class Viewer extends Component{
    constructor(props) {
        super(props);
        this.state = {
            ok:'',
            show:'1',
        }
    }



    render(){
        return (
            this.state.show &&
            <Fragment>
            <div className="viewer-wrapper"></div>
            </Fragment>
            
        )
    }
}

export default Viewer;
import React, {Component} from 'react';
import { Link, NavLink } from 'react-router-dom';

import Logo from '../../img/horizontal_color.png';

class Navbar extends Component{
    constructor(props) {
        super(props);
        this.state = {
            openMobileNav: false
        };
        this.handleOpenNav= this.handleOpenNav.bind(this);
        this.closeNav= this.closeNav.bind(this);
    }
    
    handleOpenNav(e){
        if(!this.state.openMobileNav){
            this.setState({ openMobileNav: true });
        }else{
            this.setState({ openMobileNav: false});
        }
    }
    closeNav(e){
        this.setState({ openMobileNav: false});
    }
    render(){
        return (
        <header>
            <Link to="/" className="header-logo" onClick={this.closeNav} >
                <img src={Logo} alt="Alexandre Vranx - Real Eastate & Photography" />
            </Link>
            <div className="menu">
                <nav className="nav">
                    <ul  className="nav-ul">
                        <li className="nav-li"  >
                            <NavLink exact to="/" className="nav-a" activeClassName="nav-a-active"  >Accueil</NavLink>
                        </li>
                        <li className="nav-li"  >
                            <NavLink to="/portfolio" className="nav-a" activeClassName="nav-a-active"  >Portfolio</NavLink>
                        </li>
                        <li className="nav-li"  >
                            <NavLink to="/comparaison" className="nav-a" activeClassName="nav-a-active"  >Avant/Après</NavLink>
                        </li>
                        <li className="nav-li" >
                            <NavLink to="/services" className="nav-a" activeClassName="nav-a-active"  >Services</NavLink>
                        </li> 
                        <li className="nav-li" >
                            <NavLink to="/contact" className="nav-a" activeClassName="nav-a-active"  >Contact</NavLink>
                        </li>
                        {/*
                        <li className="nav-li" >
                            <NavLink to="/devis" className="nav-a-devis"  >Devis</NavLink>
                        </li>
                        */}
                    </ul>
                </nav>
            </div>
            <div className="header-sn">
                    <a href="https://www.facebook.com/AlexandreVrancx/" target="_blank" rel="noopener noreferrer" className="header-sn-a" ><i className="fab fa-facebook-square fa-2x"></i></a>
                    <a href="https://www.instagram.com/alexandrevrancx/" target="_blank" rel="noopener noreferrer" className="header-sn-a" ><i className="fab fa-instagram fa-2x"></i></a>
            </div>
            <div className="header-mobile">
                <div className="mobile-nav-btn" onClick={this.handleOpenNav}>
                    <i className="fas fa-bars"></i>
                </div>
                {this.state.openMobileNav &&
                <div className="mobile-nav">
                    <div className="mobile-nav">
                    <nav>
                        <ul  className="mobile-nav-ul">
                            <li className="mobile-nav-li"  >
                                <NavLink exact to="/" className="mobile-nav-a" activeClassName="mobile-nav-a-active" onClick={this.handleOpenNav} >Home</NavLink>
                            </li>
                            <li className="mobile-nav-li"  >
                                <NavLink to="/portfolio" className="mobile-nav-a" activeClassName="mobile-nav-a-active" onClick={this.handleOpenNav} >Portfolio</NavLink>
                            </li>
                            <li className="mobile-nav-li"  >
                                <NavLink to="/comparaison" className="mobile-nav-a" activeClassName="mobile-nav-a-active" onClick={this.handleOpenNav} >Avant/Après</NavLink>
                            </li>
                            <li className="mobile-nav-li" >
                                <NavLink to="/services" className="mobile-nav-a" activeClassName="mobile-nav-a-active" onClick={this.handleOpenNav} >Services</NavLink>
                            </li> 
                            <li className="mobile-nav-li" >
                                <NavLink to="/contact" className="mobile-nav-a" activeClassName="mobile-nav-a-active" onClick={this.handleOpenNav} >Contact</NavLink>
                            </li>
                            {/*}
                            <li className="mobile-nav-li" >
                                <NavLink to="/devis" className="mobile-nav-a" activeClassName="mobile-nav-a-active" onClick={this.handleOpenNav} >Devis</NavLink>
                            </li>
                            */}
                        </ul>
                    </nav>
                </div>
                </div>
                }
            </div>
        </header>

        )
    }
}

export default Navbar;
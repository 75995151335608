import React, {Component, Fragment} from 'react';

import {Link} from 'react-router-dom';

import './Estimate.css';


class EstiPortrait extends Component{
    constructor(props) {
        super(props);
        this.state = {
            step: '1',
            category: '',
            decor:'',
            haveDecorationElement:'unknown',
            error: '',
            usage: '',
            locationType: '',
        }
        this.setCategory = this.setCategory.bind(this);
        this.setDecor = this.setDecor.bind(this);
        this.setDecorationElement = this.setDecorationElement.bind(this);
        this.setStep = this.setStep.bind(this);
        this.setUsage = this.setUsage.bind(this);
        this.setLocationType = this.setLocationType.bind(this);
    }
    setCategory = (category) => (e) => {
        if(category !== "Studio"){
            this.setState({
                category:category,
                step:2
            });
        }else{
            this.setState({
                category:category,
                step:3
            });
        }
        e.preventDefault();
    }
    setUsage = (usage) => (e) => {
        this.setState({
            usage:usage,
            step:4
        });
        e.preventDefault();
    }
    setDecor = (e) => {
        this.setState({ decor: e.target.value });
    }
    setLocationType = (e) => {
        this.setState({ locationType: e.target.value });
    }
    setDecorationElement = (haveDecorationElement) => (e) => {
        this.setState({ haveDecorationElement: haveDecorationElement });
    }
    setStep= (step) => (e) => {
        this.setState({ step: step, error:'' });
    }
    render(){
        return (
        <Fragment>
            <center>
                <h3>Devis Portrait</h3>
            </center>
            {this.state.step==1 &&
                <Fragment>
                    <center>
                        <h1 className="estimate-title">Type de décor :</h1>
                    </center>
                    <div className="estimate-container content">
                        <div to="/devis/immo" className="estimate-content" onClick={this.setCategory('Custom')}>
                            <div className="estimate-element">
                            </div>
                            <div className="estimate-element-title">
                                Personnalisé
                            </div>
                        </div>
                        <div to="/portfolio/immobilier" className="estimate-content" onClick={this.setCategory('Nature')}>
                            <div className="estimate-element">
                            </div>
                            <div className="estimate-element-title">
                                Nature/Extérieur 
                            </div>
                        </div>
                        <div to="/portfolio/immobilier" className="estimate-content" onClick={this.setCategory('Studio')}>
                            <div className="estimate-element">
                            </div>
                            <div className="estimate-element-title">
                                Studio
                            </div>
                        </div>    
                    </div>
                </Fragment>
            }
            {this.state.step==2 &&
                <Fragment>
                    {this.state.category === "Custom" &&
                    <Fragment>
                        <center>
                            <h1 className="estimate-title">Décor Personnalisé</h1>
                        </center>
                        <div className="content estimate-form">
                        <label className="estimate-form-decor-label">Type de décor souhaité :</label><br /><br />
                        <input type="text" className="decor" name="decor" onChange={this.setDecor} /><br /><br />   
                        <label className="estimate-form-decor-label">Disposez-vous des éléments décoratifs nécéssaires ? </label> <br /> <br />
                        <div className={`yes ${this.state.haveDecorationElement==1 ? "choice-selected": "choice-no-selected" }`} onClick={this.setDecorationElement(1)}>Oui</div><div className={`no ${this.state.haveDecorationElement==0 ? "choice-selected": "choice-no-selected" }`} onClick={this.setDecorationElement(0)}>Non</div>
                            <br />
                        </div><br /><br />
                        <center>
                            {!this.state.error ?
                            <div className="estimate-btn" onClick={this.setStep(3)}>Suivant</div>
                            :
                            <div className="error">{this.state.error}</div>
                            }
                        </center>
                    </Fragment>
                    }
                    {this.state.category === "Nature" &&
                    <Fragment>
                        <center>
                            <h1 className="estimate-title">Décor Extérieur</h1>
                        </center>
                        <div className="content estimate-form">
                        <label className="estimate-form-decor-label">Type de lieu souhaité :</label><br /><br />
                        <input type="text" className="decor" name="decor" onChange={this.setLocationType} /><br /><br />   
                        
                        </div><br /><br />
                        <center>
                            {!this.state.error ?
                            <div className="estimate-btn" onClick={this.setStep(3)}>Suivant</div>
                            :
                            <div className="error">{this.state.error}</div>
                            }
                        </center>
                    </Fragment>
                    }
                </Fragment>
            }
            {this.state.step==3 &&
                <Fragment>
                    <center>
                        <h1 className="estimate-title">Usage :</h1>
                    </center>
                    <div className="estimate-container content">
                        <div to="/devis/immo" className="estimate-content" onClick={this.setUsage('Privé')}>
                            <div className="estimate-element">
                            </div>
                            <div className="estimate-element-title">
                                Privé
                            </div>
                        </div>
                        <div to="/portfolio/immobilier" className="estimate-content" onClick={this.setUsage('Commercial')}>
                            <div className="estimate-element">
                            </div>
                            <div className="estimate-element-title">
                                Commercial
                            </div>
                        </div> 
                    </div>
                </Fragment>
            }
            {this.state.step==4 &&
                <Fragment>
                    <center>
                        <h1 className="estimate-title">Informations personnelles :</h1>
                    </center>
                    <div className="estimate-form-info">
                        <form>
                            <label>Pays </label><br/>
                            <input type="text"  className="estimate-input" placeholder="Pays" /> <br />
                            <label>Ville </label><br/>
                            <input type="text"  className="estimate-input" placeholder="Ville" /> <br />
                            <label>Nom et prénom </label><br/>
                            <input type="text"  className="estimate-input" placeholder="Nom & Prénom" /> <br /> 
                            <label>Numéro de téléphone </label><br/>
                            <input type="tel"   className="estimate-input" placeholder="Numéro de téléphone" /> <br /> 
                            <label>Adresse email </label><br/>
                            <input type="email" className="estimate-input" placeholder="Adresse email" /> <br /> <br />

                            <center>
                                {!this.state.error ?
                                <button className="estimate-calculate-btn" onClick={this.setStep(4)}>Calculer le prix</button>
                                :
                                <div className="error">{this.state.error}</div>
                                }
                            </center>
                        </form>
                        </div>
                </Fragment>
            }
        </Fragment>
        )
    }
}

export default EstiPortrait;
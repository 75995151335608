import React, { Component } from 'react';
import {Switch, Route} from 'react-router-dom';

import Home from '../components/home/Home';
import Portfolio from '../components/portfolio/Portfolio';
import Services from '../components/services/Services';
import Estimate from '../components/estimate/Estimate';
import EstiImmo from '../components/estimate/EstiImmo';
import EstiPortrait from '../components/estimate/EstiPortrait';
import Contact from '../components/contact/Contact';
import BeforAfter from '../components/beforeAfter/Immo';
import BeforAfterHotel from '../components/beforeAfter/Hotel';
import CGU from '../components/cgu/CGU';


class Router extends Component {
  render() {
    return (
      <main>
          <Switch>
            <Route exact path='/' component={Home} />
            <Route exact path='/portfolio' component={Portfolio} />
            <Route exact path='/portfolio/immobilier' render={(routeProps) => (
              <Portfolio category="1" />
            )} />
            <Route exact path='/portfolio/hotellerie' render={(routeProps) => (
              <Portfolio category="2" />
            )} />
            <Route exact path='/portfolio/portrait' render={(routeProps) => (
              <Portfolio category="3" />
            )} />
            <Route exact path='/services' component={Services} />
            <Route exact path='/devis' component={Estimate} />
            <Route exact path='/devis/immo' component={EstiImmo} />
            <Route exact path='/devis/portrait' component={EstiPortrait} />
            <Route exact path='/contact' component={Contact} />
            <Route exact path='/comparaison' component={BeforAfter} />
            <Route exact path='/comparaison/hotellerie' component={BeforAfterHotel} />
            <Route exact path='/CGU' component={CGU} />
            <Route exact path='*' component={Home} />
          </Switch>
      </main>
    );
  }
}

export default Router; 
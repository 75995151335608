import React, {Component, Fragment} from 'react';

import {Link} from 'react-router-dom';


import PlanImage from '../../img/plan.jpg';


import './Services.css';


class VirtualVisit extends Component{

    render(){
        return (
            <Fragment>
            <div className="services-container content">
                <div className="services-flex">
                    <div>
                        <h2>
                        Visite Virtuelle 
                        </h2>   
                    </div>
                    <div className="services-desc">
                        Présentez vos environnements à distance de manière intéractive.
                    </div>
                </div> 
            </div>
            <div className="services-containeralt">
                <div className="services-beforafter content">
                <iframe width="100%" height="530" src="https://my.matterport.com/showcase-beta?m=DpGgoPmsDqr&amp;lang=fr&amp;help=0&amp;hl=0&amp;nt=0&amp;play=1&amp;qs=0&amp;ts=5&amp;brand=0&amp;dh=1&amp;gt=1&amp;hr=1&amp;mls=2&amp;mt=1&amp;nozoom=1" frameBorder="0" allowFullScreen="" allow="vr"></iframe>
                </div>
            </div>
            <div className=" content services-container">
                <div className="services-flex">
                    <div>
                        <h2>
                        Plans    
                        </h2>   
                    </div>
                </div> 
            </div>
            <div className="services-containeralt">
                <div className=" content services-beforafter">
                <img src={PlanImage} alt="Plan" />
                </div>
            </div>
            <div className="services-cta">
            <div className="content">
                <h2>
                    Intéressé ?
                </h2>
                <h3>
                    Réalisez votre devis en ligne en 2 minutes !
                </h3><br />
                <Link to="/contact" className="service-cta-btn">» Allons-y</Link>
            </div>
            </div>
            
        </Fragment>
        )
    }
}

export default VirtualVisit;
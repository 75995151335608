export const mainURI = 'https://api.alexandre-vrancx.be/'; //'http://localhost:8080/' //

const api = {
    fetchPortfolio: function(){
      var encodedURI = window.encodeURI(mainURI + 'api/portfolio');

      return fetch(encodedURI
        ).then(function(response) {
          return response.json()
        }).then(function(json){
            return json
        }).catch(function(e){
          return e
        });
    },
    contact: function(name, phoneNumber,email,subject,message){
      var encodedURI = window.encodeURI(mainURI + 'api/contact');

      return fetch(encodedURI, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            name:name,
            email:email,
            phoneNumber:phoneNumber,
            subject:subject,
            message:message
          })
        }).then(function(response) {
          return response.json()
        }).then(function(json){
            return json
        }).catch(function(e){
          return e
        });
    },
}
export default api;
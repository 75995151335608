import React, {Component, Fragment} from 'react';

import Api from '../../res/api'; 

import './Contact.css';


class Contact extends Component{
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            name: '',
            phoneNumber:'',
            subject: '',
            message: '',
            error: '',
            isLoading: false,
            showSent: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
      }
      handleChange(e) {
        this.setState({ [e.target.name]: e.target.value });
      }
    
      handleSubmit(e){
        e.preventDefault();
        this.setState({isLoading: true, error: '' }, () =>{
          if(!this.state.name || !this.state.subject || !this.state.email || !this.state.message){
              this.setState({error: 'Veuillez compléter tous les champs.'});
              this.setState({isLoading: false });
          }else if (!/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(this.state.email)){
              this.setState({error: 'Adresse email invalide.'});
              this.setState({isLoading: false });
          }else if (!/^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u.test(this.state.name)){
              this.setState({error: 'Votre nom et prénom ne peuvent contenir que des lettres et des tirets.'});
              this.setState({isLoading: false });
          }else if(!/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(this.state.phoneNumber) && this.state.phoneNumber){
            this.setState({error: 'Numéro de téléphone invalide.'});
            this.setState({isLoading: false });
          }else if(this.state.name.length<6){
              this.setState({error: 'Votre nom et prénom  doivent contenir au moins 6 caractères au total.'});
              this.setState({isLoading: false });
          }else if(this.state.message.length<10){
              this.setState({error: 'Votre message est trop court.'});
              this.setState({isLoading: false });
          }else{
              Api.contact(this.state.name, this.state.phoneNumber ,this.state.email,this.state.subject, this.state.message)
              .then( response =>{
                  if(response.info){
                      this.setState({showSent: true});
                  }else{
                      console.log(response)
                      this.setState({error: "Une erreur s'est produite, veuillez réessayer."});
                  }
                  this.setState({isLoading: false });
              }).catch(error => {
                  console.log(error);
                  this.setState({error: "Une erreur s'est produite, veuillez réessayer."});
                  this.setState({isLoading: false });
              });
          }
      });
    }

    render(){
        return (
        <Fragment>
            <div className="contact-container">
                <div className="contact-picture">
                </div>
                <div className="contact-content">
                    <div className="contact-content-element">
                    <h1>Vous avez une question ?<br /> Contactez-moi !</h1>
                    {this.state.showSent ?
                    <center>
                        <br />
                        <h2>
                            MERCI
                        </h2>
                        <h3>
                            Votre message a été envoyé avec succès.<br />
                        </h3>
                    </center>
                    :
                    <form  onSubmit={this.handleSubmit} >
                        <div className="contact-input-line">
                            <div className="contact-input-line-element">
                                <label>Votre Nom</label><br />
                                <input type="text" className="contact-input-line-input" placeholder="Nom" name="name" onChange={this.handleChange}  disabled={this.state.isLoading} />
                            </div>
                            <div className="contact-input-line-element">
                                <label>Numéro de téléphone</label><br />
                                <input type="tel" className="contact-input-line-input" placeholder="Téléphone" name="phoneNumber" onChange={this.handleChange}  disabled={this.state.isLoading} />
                            </div>
                        </div>
                        <div className="contact-input-line">
                            <div className="contact-input-line-element">
                                <label>Sujet</label><br />
                                <input type="text" className="contact-input-line-input" placeholder="Sujet" name="subject" onChange={this.handleChange}  disabled={this.state.isLoading} />
                            </div>
                            <div className="contact-input-line-element">
                                <label>Adresse email</label><br />
                                <input type="email" className="contact-input-line-input" placeholder="Email" name="email" onChange={this.handleChange}  disabled={this.state.isLoading} />
                            </div>
                        </div>
                        <div className="contact-textarea-container">
                            <label>Votre message</label><br />
                            <textarea className="contact-textarea" rows="10" placeholder="Message..." name="message" onChange={this.handleChange}  disabled={this.state.isLoading}></textarea>
                        </div>
                        <center>
                        <button className="contact-btn" disabled={this.state.isLoading} >Envoyer</button>
                        {this.state.error && 
                        <div className="contact-error"><br />
                            {this.state.error}
                        </div>
                        }
                        </center>
                    </form>
                    }
                    </div>
                </div>
            </div>
        </Fragment>
        )
    }
}

export default Contact;
import React, {Component, Fragment} from 'react';

import Photography from './Photography';
import VirtualVisit from './VirtualVisit';

import './Services.css';


class Services extends Component{
    constructor(props) {
        super(props);
        this.state = {
            category:'1',
            vidInt: ''
        }
        this.setCategory = this.setCategory.bind(this);
    }
    setCategory = (category) => (e) => {
        this.setState({ category: category });
    }


    render(){
        return (
        <Fragment>
            
            <div className="service-selector">
                <div className={`service-category1 ${this.state.category==1 ? "choice-selected": "choice-no-selected" }`} onClick={this.setCategory(1)}>Photographie</div>
                <div className={`service-category2 ${this.state.category==2 ? "choice-selected": "choice-no-selected" }`} onClick={this.setCategory(2)}>Visite virtuelle </div> 
            </div>
            {this.state.category==1 &&
                <Photography />
            }
            {this.state.category==2 &&
                <VirtualVisit />
            }
        </Fragment>
        )
    }
}

export default Services;
import React, {Component, Fragment} from 'react';

import {Link} from 'react-router-dom';

import './Estimate.css';


class Estimate extends Component{
    constructor(props) {
        super(props);

    }

    render(){
        return (
        <Fragment>
            <center>
                <h3>Devis </h3>
                <h1 className="estimate-title">Catégories :</h1>
            </center>
            <div className="estimate-container content">
            <Link to="/devis/immo" className="estimate-content">
                    <div className="estimate-element">
                    </div>
                    <div className="estimate-element-title">
                        Immobilier
                    </div>
                </Link>
                <Link to="/portfolio/immobilier" className="estimate-content">
                    <div className="estimate-element">
                    </div>
                    <div className="estimate-element-title">
                        Hôtellerie
                    </div>
                </Link>
                <Link to="/devis/portrait" className="estimate-content">
                    <div className="estimate-element">
                    </div>
                    <div className="estimate-element-title">
                        Portrait
                    </div>
                </Link>
                
            </div>
        </Fragment>
        )
    }
}

export default Estimate;
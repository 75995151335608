import React, { Component } from 'react';

import Router from './router/Routes';
import Navbar from './components/common/Navbar';
import Footer from './components/common/Footer';
import ScrollToTop from './components/common/ScrollToTop';

import './css/App.css';
import './css/Animations.css';

class App extends Component {
  render() {
    return (
      <ScrollToTop>    
          <Navbar />
          <div className="wrapper-mobile">
          <Router />
          </div>
          <Footer />
      </ScrollToTop>
    );
  }
}

export default App;

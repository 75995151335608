import React, {Component, Fragment} from 'react';

class CGU extends Component{
    constructor(props) {
        super(props);
    }

    render(){
        return (
        <Fragment>
            <div className="services-container content">
                <div className="services-flex">
                    <div>
                        <h2>
                        Conditions générales de vente et de prestations de services
                        </h2>   
                    </div>
                </div> 
            </div>
            <div className="services-containeralt">
                <div className="content">
                    <h3>1. Présentation de Alexandre Vrancx Real Estate & Photography et des Produits et Services</h3>
                    <p>
                    Alexandre Vrancx Real Estate & Photography, dont le siège social est situé à 7100 Haine-Saint-Pierre, rue de la compagnie centrale 80/201, a conçu et développé une activité spécifique de communication visuelle, par l’intermédiaire de M. Alexandre Vrancx (BCE 0673.657.575), consistant notamment en la réalisation de reportages photographiques, de visites virtuelles, de vidéos de présentation et d’aménagement en 3D.
                    </p> 
                    <h3>2. Champ d'application</h3>
                    <p>
                    2.1. L’application des présentes conditions générales constitue une condition déterminante du consentement de Alexandre Vrancx Real Estate & Photography. Toute commande passée implique l’adhésion pleine et entière du Client aux présentes conditions générales et exclut l'application des éventuelles conditions générales du Client. Alexandre Vrancx Real Estate & Photography se réserve le droit de modifier les présentes conditions générales à tout moment. Ces modifications s'appliqueront à toutes commandes futures passées par le Client.
                    </p>
                    <p>
                    2.2. Les présentes conditions générales sont accessibles à tout moment sur le site web de Alexandre Vrancx Real Estate & Photography de sorte qu'en passant commande auprès de Picto Visio, le Client confirme son acceptation des présentes conditions générales à tout moment, sans notification préalable.
                    </p>
                    <h3>3. Commande </h3>
                    <p> 
                    3.1. Pour passer commande, le Client choisit le ou les Produit(s) ou Service(s) qu'il souhaite commander et en informe Alexandre Vrancx Real Estate & Photography par téléphone, courrier ou email ou tout autre moyen. Alexandre Vrancx Real Estate & Photography adresse au Client un bon de commande relatif à ces Produits et/ou Services. Le bon de commande contient une mention rappelant au Client l'application des présentes conditions générales. Le Client doit ensuite accepter et renvoyer le bon de commande signé à Alexandre Vrancx Real Estate & Photography, afin de confirmer sa commande.
                    </p>
                    <p>
                    3.2. Alexandre Vrancx Real Estate & Photography se réserve le droit de suspendre ou de refuser la commande, notamment dans le cas où les données communiquées par le Client s'avèrent manifestement erronées ou incomplètes.
                    </p> 
                    <h3>4. Offre</h3> 
                    <p>
                    4.1 Les devis et/ou les offres n’engagent pas Alexandre Vrancx Real Estate & Photography, sauf après signature de ceux-ci par le Client.
                    </p>
                    <p>
                    4.2. Le délai de validité des offres émises par Alexandre Vrancx Real Estate & Photography est de 30 jours.
                    </p>
                    <p>
                    4.3. Les prix indiqués dans l’offre ne visent que la vente des Produits ou la réalisation des Services qui y sont décrits, à l’exclusion de toutes autres prestations.
                    </p>
                    <p> 
                    4.4. L’offre de prix est faite sur la base des informations fournies par le Client. En cas de modification quelconque de la prestation souhaitée, Alexandre Vrancx Real Estate & Photography est autorisé à revoir le prix fixé initialement. Toute modification de la commande devra être expressément approuvée par M. VRANCX. 
                    </p>
                    <h3>5. Prix et paiement</h3>
                    <p> 
                    5.1. Tous les prix sont indiqués en euros, TVA non comprise.
                    </p>
                    <p>
                    5.2. Dès communication par le Client de son bon de commande à Alexandre Vrancx Real Estate & Photography, Alexandre Vrancx Real Estate & Photography émet sa facture correspondant à l’acompte réclamé ou prix convenu pour les Produits et Services commandés par le Client. Le cas échéant, une facture complémentaire correspondant au solde du prix des Produits et Services commandés sera émise dès que la commande est prête à être livrée ou réceptionnée par le Client.
                    </p>
                    <p>
                    5.3. Les factures sont payables au comptant ou à toute autre échéance qu’elles mentionnent expressément, au crédit du compte de’ Alexandre Vrancx Real Estate & Photography, et en tout état de cause, à la livraison du ou des Produit(s) et/ou Service(s) commandé(s) par le Client. En cas de non-paiement de toute facture à l'échéance, le Client sera redevable, de plein droit et sans mise en demeure, des intérêts au taux de 12% l'an, ainsi que d'une indemnité forfaitaire et irréductible de 15% du montant resté impayé, avec un minimum de 75€.
                    </p>
                    <p>
                    5.5. Toute réclamation relative à une facture devra être transmise au plus tard huit jours calendrier après sa réception. A défaut, le Client ne pourra plus contester cette facture.
                    </p>
                    <h3>6. Livraison</h3>
                    <p>
                    6.1. Les délais de livraison indiqués par Alexandre Vrancx Real Estate & Photography ne sont donnés qu'à titre indicatif et ne lient pas Alexandre Vrancx Real Estate & Photography. Un retard dans la livraison ne pourra en aucun cas donner lieu à une quelconque indemnité, résolution du contrat ou suspension des obligations du Client, ou au paiement de dommages et intérêts au Client par Alexandre Vrancx Real Estate & Photography. 
                    </p>
                    <p>
                    6.2. La commande n'est livrée au Client ou exécutée qu'après émission de la facture finale. Le transfert de la propriété et de la charge des risques liés aux Produits s'opère au moment où la commande est entièrement payée. 
                    </p>
                    <p>
                    6.3. Lorsque les circonstances rendent impossible l'exécution de la livraison ou de la prestation - notamment dans tous les cas de force majeure tels que grève, lock-out, accident, maladie, intempérie, blocus, défense d'importation ou d'exportation, cessation de production ou de livraison par le fournisseur, etc. –  Alexandre Vrancx Real Estate & Photography se réserve expressément le droit de résilier ses engagements envers le Client, le tout sans indemnité. Le Client est tenu de mettre tout en œuvre pour permettre à Alexandre Vrancx Real Estate & Photography de réaliser ses prestations. Toute violation de cet engagement ou tout défaut d’information du Client sur des éléments essentiels à la réalisation des Services ou la livraison de Produits par Alexandre Vrancx Real Estate & Photography autorise Alexandre Vrancx Real Estate & Photography à résilier ses engagements envers le Client, le tout sans indemnité
                    </p>
                    <h3>7. Propriété intellectuelle</h3>
                    <p>
                    Le Client ne dispose d'aucun droit de propriété intellectuelle sur les Produits vendus et leurs composantes (images, données, documents, fichiers, etc.). Le Client n’est donc pas autorisé à modifier, reproduire, louer, emprunter, vendre, distribuer ou créer d’œuvres dérivées basées en tout ou en partie sur les éléments présentés dans les Produits ou Services de Alexandre Vrancx Real Estate & Photography.
                    </p>
                    <h3>8. Résiliation du contrat</h3>
                    <p>
                    8.1. En cas de non-paiement des sommes dues par le Client, Alexandre Vrancx Real Estate & Photography pourra résilier le contrat dans un délai de 15 jours à compter de la mise en demeure adressée par mail et qui n'aurait pas été suivie du paiement intégral du prix en principal, intérêts et clause pénale.
                    </p>
                    <p> 
                    8.2. En cas de résiliation par le Client, toute somme déjà versée à Alexandre Vrancx Real Estate & Photography ne lui sera pas remboursée. Si aucune somme n'a encore été payée, Alexandre Vrancx Real Estate & Photography sera en droit de réclamer au Client une indemnité de résiliation équivalente à 30% du prix total des Produits et Services dont la commande a été résiliée par le Client. Les contrats d’abonnement sont conclus pour une durée déterminée fixée dans le bon de commande, et sauf notification préalable, ils sont tacitement reconduis pour la même durée. Ils ne peuvent être résiliés anticipativement. Toute résiliation par le Client sera donc considérée comme fautive et engendrera le paiement par le Client à Alexandre Vrancx Real Estate & Photography d’une indemnité égale aux montants qui auraient été perçus par Picto Visio si le contrat s’était poursuivi jusqu’à son terme. 
                    </p>
                    <h3>9. Responsabilité et Garantie</h3>
                    <p>
                    Alexandre Vrancx Real Estate & Photography s'engage à exécuter les prestations demandées en bon père de famille. Ses obligations sont qualifiées d’obligations de moyen.
                    </p>
                    <h3>10. Confidentialité</h3>
                    <p>
                    Les éléments donnés par le Client à Alexandre Vrancx Real Estate & Photography le sont sous l’entière et seule responsabilité du Client. Alexandre Vrancx Real Estate & Photography s’engage à traiter les données à caractère personnel conformément à la loi du 8 décembre 1992, relative à la protection de la vie privée à l’égard des traitements de données à caractère personnel.
                    </p>
                    <h3>11. Réserve de propriété</h3>
                    <p>
                    Alexandre Vrancx Real Estate & Photography reste propriétaire des Produits commandés jusqu’à leur complet paiement. Le droit de propriété des produits n’est transmis au Client qu’après le retrait ou la livraison des articles et après le paiement intégral de la commande. Par dérogation à l’article 1583 du code civil, les articles vendus, livrés ou installés restent donc la propriété exclusive de Alexandre Vrancx Real Estate & Photography jusqu’au règlement intégral de la facture. 
                    </p>
                    <h3>12. Clauses diverses</h3>
                    <p>
                    12.1. Autonomie des dispositions : Si l’une ou plusieurs des dispositions des présentes conditions générales s’avèrent invalides, illégales ou inexécutables, de quelque façon que ce soit, les autres dispositions demeureront pleinement en vigueur. Lesdites dispositions seront alors modifiées dans la stricte mesure nécessaire à leur exécution.
                    </p>
                    <p>
                    12.2. Décharge de responsabilité : Aucune action de l’une ou l’autre des Parties, autre qu’une renonciation explicite par écrit, ne peut être interprétée comme une renonciation à l’une quelconque des dispositions des présentes conditions générales. Le retard de l’une ou l’autre des Parties à exercer l’un de ses droits ou l’une de ses prérogatives, et l’exercice partiel ou isolé par l’une ou l’autre des parties d’un desdits droits ou prérogatives ne pourra empêcher un autre exercice ou un exercice plus complet de ces mêmes droits ou prérogatives. La décharge de responsabilité par rapport à un droit ou une prérogative à un moment donné ne pourra être interprétée comme s’opposant à l’exercice du même droit ou prérogative à une date ultérieure. 
                    </p>
                    <h3>13. Droit applicable et Compétence</h3>
                    <p>
                    13.1. Toute contestation relative à la validité, l’interprétation et/ou l’exécution d’un contrat conclu avec le M. VRANCX sera exclusivement soumise aux Cours et Tribunaux de l'arrondissement de Mons ou à la justice de paix du canton de La Louvière.
                    </p>
                    <p>
                    13.2. Tous les litiges concernant le contrat conclu avec le Client sont régis par le droit belge
                    </p>
                </div>
            </div>

            
        </Fragment>
        )
    }
}

export default CGU;